import React, { useState } from 'react';
import DashboardLayoutWrapper from '../BidderLayout';
import Tab from './Components/Tab';
import ApprovalPending from './Components/ApprovalPending';
import RejectedBids from './Components/RejectedBids';
import UpForFareCalculation from './Components/UpForFareCalculation';
import FareValueCalculated from './Components/FareValueCalculated';

export default function BidsInProgress() {
  const [tab, setTab] = useState("Approval Pending")
  const data = ["Approval Pending", "Rejected Bids", "Up For Fare Calculation", "Fare Value Calculated"]

  return (
    <DashboardLayoutWrapper>
      <Tab setTab={setTab} tab={tab} />
      {tab === "Approval Pending" && <ApprovalPending />}
      {tab === "Rejected Bids" && <RejectedBids />}
      {tab === "Up For Fare Calculation" && <UpForFareCalculation />}
      {tab === "Fare Value Calculated" && <FareValueCalculated />}
    </DashboardLayoutWrapper>
  );
}
