const Tab = ({ tab, setTab }) => {
    const data = ["Approval Pending", "Rejected Bids", "Up For Fare Calculation", "Fare Value Calculated"]
    return <div className="tab-container">
        {data?.map((item) => {
            return <div className={`tab ${tab === item ? "active-tab" : ""}`}
                onClick={() => setTab(item)}
            >{item}</div>
        })}
    </div>
}
export default Tab