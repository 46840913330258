import { Table } from "../../../../../../components/common"
import DateUtils from "../../../../../../utils/date-utils";

const FareValueCalculated = () => {
    return <Table
        data={data}
        // limit={perPageLimit}
        // totalCount={bids?.count}
        columns={columns}
    // currentPage={currentPageNumber}
    // handlePageChange={handlePageChange}
    // handleRowsPerPageChange={handleRowsPerPageChange}
    // onRowClick={handleRowClick}
    />
}
export default FareValueCalculated
const columns = [
    {
        name: "S.No",
        selector: (row, index) => index + 1,
        sortable: false,
        width: "90px",
    },
    {
        name: "Request ID",
        selector: (row) => row.requestId,
        width: "150px",
    },
    {
        name: "Time Left",
        selector: (row) => row.timeLeft,
        width: "150px",
    },
    {
        name: "Bid ID",
        selector: (row) => row.bidId,
        width: "150px",
    },
    {
        name: "Category",
        selector: (row) => row.category,
        width: "150px",
    },
    {
        name: "Vessel",
        selector: (row) => row.vessel,
        width: "200px",
    },
    {
        name: "Country",
        selector: (row) => row.country,
        width: "200px",
    },
    {
        name: "ETA",
        selector: (row) => DateUtils?.formatDateObject(row.serviceRequestOn),
        width: "200px",
    },
    {
        name: "ETD",
        selector: (row) => DateUtils?.formatDateObject(row.serviceRequestOn),
        width: "200px",
    },
];
const data = [
    {
        id: 1,
        requestId: "REQ001",
        timeLeft: "24h 30m",
        bidId: "BID001",
        category: "Electronics",
        vessel: "Vessel A",
        country: "USA",
        serviceRequestOn: new Date(2023, 8, 5, 10, 30),
    },
    {
        id: 2,
        requestId: "REQ002",
        timeLeft: "12h 15m",
        bidId: "BID002",
        category: "Automobile",
        vessel: "Vessel B",
        country: "Canada",
        serviceRequestOn: new Date(2023, 8, 7, 12, 45),
    },
];